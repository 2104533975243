import {
  Container,
  Heading,
  Card,
  CardHeader,
  CardBody,
  Text,
  CardFooter,
  Button,
  Tooltip,
  Box,
  Image,
  Divider,
} from "@chakra-ui/react";
import React from "react";

import { Link } from "react-router-dom";

import classonebsc from "../../../Assets/class-i-bsc-airflow-diagram.jpg";
import classtwobsc from "../../../Assets/class-ii-b1-bsc-airflow-diagram.jpg";

import GoToTop from "../../GoToTop/GoToTop";

function BiologicalSafetyCabinet() {
  return (
    <Container maxW={"container.lg"}>
      <Card mb={20}>
        <CardHeader>
          <Heading size="lg" bg={"blue.500"} color={"white"} p={5}>
            {" "}
            Biological Safety Cabinet
          </Heading>
        </CardHeader>
        <CardBody>
          <Text>
            A Biological Safety Cabinet (BSC), also known as a Biosafety Cabinet
            is mainly used for handling pathogenic biological samples or for
            applications that require a sterile work zone. A biological safety
            cabinet creates inflow and downflow of air that provides operator
            protection. The downflow air passes through an ULPA/HEPA filter and
            creates an ISO Class 3 work zone to protect samples from the risk of
            cross-contamination. The air exhausted also passes through an
            ULPA/HEPA filter prior to release to protect the environment.
          </Text>

          <Text>
            Esco biosafety cabinets have a wide range of options from work
            trays, side panel construction to accessories. Esco BSCs are
            designed with ergonomic and energy-saving features.
          </Text>

          <br />

          <Text mb={40}>
            To guarantee the biological safety cabinet’s optimum performance
            these are tested and classified by 2 standards: <br />
            <Text
              as={"span"}
              color={"blue.500"}
              fontWeight={"extrabold"}
              cursor={"pointer"}
            >
              <Tooltip
                label="EN 12469: 2000 Biotechnology – Performance criteria for microbiological safety cabinets is the new harmonized European standard for microbiological safety cabinets, published by CEN, the European Committee for Standardization. This standard classifies BSCs into to Class I, Class II, and Class III."
                aria-label="EN 12469"
              >
                EN 12469:2000
              </Tooltip>
            </Text>{" "}
            and{" "}
            <Text
              as={"span"}
              color={"blue.500"}
              fontWeight={"extrabold"}
              cursor={"pointer"}
            >
              <Tooltip
                label="The NSF International (formerly The National Sanitation Foundation) Biological Safety Cabinetry Program was initiated during the 1970s at the request of the regulatory community, including the Centers for Disease Control (CDC), National Institutes of Health (NIH), and the National Cancer Institute (NCI). This standard further classifies Class II Biosafety Cabinets according to subtypes based on air circulation: Class II Type A1, Class II Type A2, Class II Type B1, and Class II Type B2."
                aria-label="EN 12469"
              >
                NSF 49
              </Tooltip>
            </Text>{" "}
          </Text>

          <Box w={"full"} mb={20}>
            <Heading mb={10}>Class I Biological Safety Cabinet</Heading>
            <Image
              src={classonebsc}
              boxSize="fit-content"
              objectFit="cover"
              margin={"auto"}
              alt={"Class I Biological Safety Cabinet"}
            />
            <Text>
              A Class I Biosafety cabinet protects the operator and the
              environment from exposure to biohazards. It does not prevent
              samples being handled in the cabinet from being exposed to
              contaminants that may be present in room air. Naturally, there is
              a possibility of cross-contamination that may affect experimental
              consistency. Consequently, the scope and application of Class I
              cabinets is limited, and it is largely considered obsolete.
            </Text>
          </Box>

          <Divider mb={20} />

          <Box>
            <Heading mb={10}>Class II Biological Safety Cabinet</Heading>
            <Image
              src={classtwobsc}
              boxSize="fit-content"
              objectFit="cover"
              margin={"auto"}
              alt={"Class II Biological Safety Cabinet"}
            />
            <Text>
              <br />
              <Text as={"span"} color={"blue.500"} fontWeight={"extrabold"}>
                Class II Type A2
              </Text>{" "}
              <br />
              <br />
              The Class II Type A2 biological safety cabinet is the most common
              Class II cabinet. It has a plenum from which 30% of air is
              exhausted, and 70% re-circulated to the work area as the downflow.
              Stated from NSF/ ANSI 49:2010, both the Class II Type A1 and Type
              A2 must have the positively-pressurized contaminated plenum to be
              surrounded by negative pressure.
              <br />
              <br /> In case there is a leakage on the positive plenum, the
              leaking aerosol will be pulled by the negative pressure back to
              the positive plenum, and it will not leak out. In the A2 cabinet,
              about 70% of air from the positive plenum is recirculated as
              downflow, and the remaining 30% is discharged to the lab through
              the exhaust filter.
            </Text>
          </Box>

          <br />
          <br />

          <Box>
            <Image
              src={classtwobsc}
              boxSize="fit-content"
              objectFit="cover"
              margin={"auto"}
              alt={"Class II Type B1"}
            />
            <Text>
              <br />
              <Text as={"span"} color={"blue.500"} fontWeight={"extrabold"}>
                Class II Type B1
              </Text>{" "}
              <br />
              <br />
              The Class II Type B1 biological safety cabinet has a common plenum
              from which 70% of air is exhausted, and 30% re-circulated to the
              work area as the downflow. This cabinet also has a dedicated
              exhaust feature that eliminates re-circulation when work is
              performed towards the back within the interior of the cabinet.
              Toxic chemicals employed as an adjunct to microbiological
              processes should only be allowed if they do not interfere with
              work when re-circulated in the downflow.
            </Text>
          </Box>

          <br />
          <br />

          <Box mb={20}>
            <Image
              src={classtwobsc}
              boxSize="fit-content"
              objectFit="cover"
              margin={"auto"}
              alt={"Class II Type B2"}
            />
            <Text>
              <br />
              <Text as={"span"} color={"blue.500"} fontWeight={"extrabold"}>
                Class II Type B2
              </Text>{" "}
              <br />
              <br />
              The Class II Type B2 biosafety cabinets are suitable for work with
              toxic chemicals employed as an adjunct to microbiological
              processes under all circumstances since no re-circulation occurs.
              In theory, Type B2 biological safety cabinets may be considered as
              the safest of all Class II BSCs since the total exhaust feature
              acts as a fail-safe in the event that the downflow and / or
              exhaust HEPA filtration systems cease to function normally.
              However, Class II Type B2 biosafety cabinets require large
              laboratory spaces due to their installation system and will
              require elaborate ducting works.
            </Text>
          </Box>

          <Divider mb={20} />

          <Box>
            <Heading mb={10}>Class III Biological Safety Cabinet</Heading>
            <Text>
              Class III biological safety cabinets are suitable for work with
              microbiological agents assigned to biosafety levels 1, 2, 3 and 4.
              They are frequently specified for work involving the most lethal
              biological hazards.
            </Text>
            <br />
            <Text>
              Work is performed through glove ports in the front of the cabinet.
              During routine operation, negative pressure relative to the
              ambient environment is maintained within the biosafety cabinet.
              This provides an additional fail-safe mechanism in case physical
              containment is compromised. On all Class III BSCs, a supply of
              HEPA filtered air provides product protection and prevents cross
              contamination of samples. Exhaust air is usually HEPA filtered and
              incinerated. Alternatively, double HEPA filtration with two
              filters in series may be utilized.
            </Text>
          </Box>
        </CardBody>
        <CardFooter>
          <Link to="/Contact">
            <Button colorScheme="blue">Contact us</Button>
          </Link>
        </CardFooter>
      </Card>
      <GoToTop />
    </Container>
  );
}

export default BiologicalSafetyCabinet;
