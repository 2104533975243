import React, { useState } from "react";
import {
  Container,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  Heading,
  FormControl,
  Input,
  FormLabel,
  InputGroup,
  InputLeftAddon,
  Stack,
  Button,
  Card,
  CardBody,
  useToast,
  Text,
} from "@chakra-ui/react";

import GoToTop from "../GoToTop/GoToTop";

import emailjs from "emailjs-com";

function Contact() {
  const toast = useToast();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !email || !mobile || !message) {
      setError("Please fill in all fields.");
      return;
    }

    // Send form data
    const serviceId = "service_h1b2wqt";
    const templateId = "template_gyl7g5k";
    const userId = "ofW-dzHMZnY5MSaw0";

    const templateParams = {
      user_name: name,
      user_email: email,
      user_mobile: mobile,
      message,
    };

    emailjs
      .send(serviceId, templateId, templateParams, userId)
      .then((res) => {
        console.log("Email sent successfully!");
        toast({
          title: "Message Sent.",
          description:
            "We've received your message, Thank you for connecting with us.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setName("");
        setEmail("");
        setMobile("");
        setMessage("");
        setError("");
      })
      .catch((err) => {
        console.error("Email failed to send:", err);
        toast({
          title: "Error",
          description:
            "We failed to receive your message, try sending back or contact us at +91-81791 14666",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const handleMobileChange = (e) => {
    const { value } = e.target;
    if (!value || /^[0-9\b]+$/.test(value)) {
      setMobile(value);
    }
  };

  return (
    <Container maxW={"container.lg"}>
      <Card mb={10} bg={"#125076"} color={"white"}>
        <CardBody>
          <Heading textAlign={"center"}>Contact us</Heading>
        </CardBody>
      </Card>

      <Box mb={40}>
        <form onSubmit={handleSubmit}>
          <FormControl>
            <Stack spacing={4}>
              <InputGroup>
                <FormLabel fontWeight={"extrabold"} color={"blue.500"}>
                  Name
                </FormLabel>
                <Input
                  type="text"
                  variant="flushed"
                  borderColor={"blue.900"}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  id={"name"}
                  name={"user_name"}
                />
              </InputGroup>

              <InputGroup>
                <FormLabel fontWeight={"extrabold"} color={"blue.500"}>
                  Email address
                </FormLabel>
                <Input
                  type="email"
                  variant="flushed"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id={"email"}
                  name={"user_email"}
                  borderColor={"blue.900"}
                />
              </InputGroup>

              <InputGroup>
                <InputLeftAddon children="+91" />
                <Input
                  type="tel"
                  placeholder="phone number"
                  value={mobile}
                  onChange={handleMobileChange}
                  id={"mobile"}
                  name={"user_mobile"}
                  maxLength="10"
                />
              </InputGroup>

              <InputGroup>
                <Input
                  type="text"
                  placeholder="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  id={"message"}
                  name={"message"}
                />
              </InputGroup>

              {error && <div>{error}</div>}

              <Button
                colorScheme={"blue"}
                variant={"solid"}
                w={"fit-content"}
                type={"submit"}
              >
                Submit
              </Button>
            </Stack>
          </FormControl>
        </form>
      </Box>

      <Card mb={40} bg={"blue.500"} color={"white"} maxW={["100%", "50%"]}>
        <CardBody>
          <Heading fontSize={"xl"}>FOR SALES AND TRADE ENQUIRIES</Heading>
          <Text my={5} fontWeight={"extrabold"}>
            <ul>
              <li>Chiefexecutive@nvakins.in </li>
              <li>infosales@nvakins.in</li>
              <li>admindevp@nvakins.in</li>
            </ul>{" "}
          </Text>
          <Text>
            {" "}
            <Text as={"span"} fontWeight={"extrabold"}>
              CONTACT NO
            </Text>{" "}
            : 040-40152785
          </Text>
        </CardBody>
      </Card>

      <Heading mb={10}>Frequently Asked Questions</Heading>
      <Accordion mb={20}>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontWeight={"bold"}
                color={"blue.500"}
              >
                What types of pharmaceutical equipment do you import?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            We import a wide range of pharmaceutical equipment, including but
            not limited to, manufacturing equipment, laboratory instruments,
            packaging machinery, and process equipment.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontWeight={"bold"}
                color={"blue.500"}
              >
                Where are the pharmaceutical equipment sourced from?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Our pharmaceutical equipment is sourced from reputable manufacturers
            from around the world, including Europe, Asia, and the United
            States.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontWeight={"bold"}
                color={"blue.500"}
              >
                Are the pharmaceutical equipment that you import certified for
                use in our country?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Yes, we ensure that all the pharmaceutical equipment that we import
            are certified and compliant with the local regulations and standards
            in your country.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontWeight={"bold"}
                color={"blue.500"}
              >
                What is the typical lead time for importation of pharmaceutical
                equipment?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            The lead time for importing pharmaceutical equipment varies
            depending on the type of equipment and the location of the
            manufacturer. We will provide you with an estimated lead time based
            on your specific requirements.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontWeight={"bold"}
                color={"blue.500"}
              >
                How do you ensure that the pharmaceutical equipment is
                transported safely?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            We work with trusted logistics partners who specialize in handling
            sensitive and high-value equipment to ensure that the pharmaceutical
            equipment is transported safely and securely.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontWeight={"bold"}
                color={"blue.500"}
              >
                What is your return policy for pharmaceutical equipment that is
                damaged during transport?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            We have a comprehensive return policy that covers any damage
            incurred during transport. We will work with you to repair or
            replace the equipment as quickly as possible.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontWeight={"bold"}
                color={"blue.500"}
              >
                Can you provide training for our staff on how to use the
                pharmaceutical equipment?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Yes, we offer training services to ensure that your staff are fully
            trained on how to use the pharmaceutical equipment safely and
            effectively.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontWeight={"bold"}
                color={"blue.500"}
              >
                How do you handle customs clearance for imported pharmaceutical
                equipment?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            We have a team of experienced customs clearance agents who will
            handle all the necessary paperwork and procedures to ensure that the
            pharmaceutical equipment is cleared through customs smoothly and
            efficiently.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontWeight={"bold"}
                color={"blue.500"}
              >
                Do you offer any warranty or maintenance services for the
                pharmaceutical equipment that you import?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Yes, we offer warranty and maintenance services for the
            pharmaceutical equipment that we import to ensure that the equipment
            is operating at peak performance.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontWeight={"bold"}
                color={"blue.500"}
              >
                What is the cost of importing pharmaceutical equipment?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            The cost of importing pharmaceutical equipment varies depending on
            several factors, including the type of equipment, the manufacturer's
            location, and the import duties and taxes. We will provide you with
            a detailed cost breakdown based on your specific requirements.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontWeight={"bold"}
                color={"blue.500"}
              >
                How do you handle disputes or issues that arise during the
                importation process?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            We have a dedicated customer service team who will work with you to
            resolve any disputes or issues that arise during the importation
            process.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontWeight={"bold"}
                color={"blue.500"}
              >
                Are there any restrictions on the importation of pharmaceutical
                equipment in our country?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            The regulations and restrictions on the importation of
            pharmaceutical equipment vary depending on your country's laws and
            regulations. We will work with you to ensure that all the necessary
            requirements are met.
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <GoToTop />
    </Container>
  );
}

export default Contact;
