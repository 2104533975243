import {
  Container,
  Heading,
  Text,
  Stack,
  SimpleGrid,
  Box,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  StackDivider,
  Image,
  ListItem,
  UnorderedList,
  Icon,
  GridItem,
  Grid,
} from "@chakra-ui/react";

import GoToTop from "../GoToTop/GoToTop";

import Typewriter from "typewriter-effect";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { motion } from "framer-motion";
import React from "react";
import CountUp from "react-countup";

import { Link } from "react-router-dom";

// carousel
import map from "../../Assets/map.svg";
import wave from "../../Assets/wave.svg";
import wave3 from "../../Assets/wave3.svg";
import aragen from "../../Assets/aragen-logo.jpeg";

// points
import one from "../../Assets/one.svg";
import two from "../../Assets/two.svg";
import three from "../../Assets/three.svg";
import four from "../../Assets/four.svg";
import five from "../../Assets/five.svg";
import six from "../../Assets/six.svg";

// clients
import laurus from "../../Assets/lauruslab-logo.png";
import gland from "../../Assets/gland-logo.webp";
import vimta from "../../Assets/vimta-logo.png";
import mylan from "../../Assets/mylan-logo.svg";
import drreddy from "../../Assets/dr-reddy-logo.svg";
import bharat from "../../Assets/bharat-biotech-logo.jpg";
import divis from "../../Assets/divis-logo.png";
import aurobindo from "../../Assets/aurobindo-logo.png";
import msn from "../../Assets/msn-logo.png";
import medical from "../../Assets/medical.svg";
import hetero from "../../Assets/Hetero-logo.png";
import aurigene from "../../Assets/aurigene-logo.png";
import be from "../../Assets/BE-logo.png";
import niab from "../../Assets/niab-logo.jpeg";

// icons
import { GiMaterialsScience } from "react-icons/gi";
import { FaBaby } from "react-icons/fa";
import { TbRibbonHealth } from "react-icons/tb";
import { MdOutlineLocalPharmacy } from "react-icons/md";
import { GiChemicalDrop } from "react-icons/gi";

function Home() {
  return (
    <Box>
      <Container maxW={"container.lg"} mb={40}>
        <Stack direction={["colum", "row"]}>
          <Heading size={["2xl", "4xl"]} mb={10}>
            NVAK <br /> Biotech Instruments
            <Box color={"#125076"}>
              <Typewriter
                options={{
                  strings: ["Network", "Vision", "Achieve", "Knowledge"],
                  autoStart: true,
                  loop: true,
                  cursor: "",
                }}
              />
            </Box>
          </Heading>
        </Stack>

        <Text
          mb={10}
          fontSize={["md", "lg"]}
          as={motion.div}
          initial={{ opacity: 0, x: "-100px" }}
          whileInView={{ opacity: 1, x: 0 }}
        >
          We are a company specializing in the importation of high-quality life
          science tools. Our mission is to provide our clients with the best
          equipment available in the market, ensuring that they have access to
          the tools they need to provide exceptional care to their customers.
          <br />
          <br />
          We are proud to be associated with major Gene, DNA & Medical research
          facilities, Pharma entities, IVF establishments and Prominent
          government medical research and development organizations along with
          other healthcare providers across the globe, providing them with the
          tools they need. Our commitment to quality, reliability, and
          innovation is at the heart of everything we do, and we are always
          looking for new ways to improve and expand our offerings.
        </Text>

        <Link to="/Contact">
          <Button colorScheme={"blue"} variant={"outline"}>
            Connect with us
          </Button>
        </Link>
      </Container>

      <Box bg={"#f9f9f9"} py={20} mb={40}>
        <Container maxW={"container.lg"}>
          <Grid
            templateColumns={[
              "repeat(1, 1fr)",
              "repeat(3, 1fr)",
              "repeat(5, 1fr)",
            ]}
            gap={6}
            textAlign={"center"}
          >
            <GridItem
              w="100%"
              as={motion.div}
              whileHover={{ scale: 1.2, color: "#3182ce" }}
              cursor={"pointer"}
            >
              <Link to={"https://www.escolifesciences.com/"}>
                <Icon
                  as={GiMaterialsScience}
                  w={20}
                  h={20}
                  color="blue.500"
                  p={2}
                />
                <Text fontWeight={"extrabold"}>Life Sciences</Text>
              </Link>
            </GridItem>
            <GridItem
              w="100%"
              as={motion.div}
              whileHover={{ scale: 1.2, color: "#3182ce" }}
              cursor={"pointer"}
            >
              <Link
                to={"https://www.escolifesciences.com/solutions/pharmaceutical"}
              >
                <Icon
                  as={MdOutlineLocalPharmacy}
                  w={20}
                  h={20}
                  color="blue.500"
                  p={2}
                />
                <Text fontWeight={"extrabold"}>Pharma Solutions</Text>
              </Link>
            </GridItem>
            <GridItem
              w="100%"
              as={motion.div}
              whileHover={{ scale: 1.2, color: "#3182ce" }}
              cursor={"pointer"}
            >
              <Link
                to={
                  "https://www.esco-medical.com/#:~:text=Esco%20Medical%20is%20the%20leading,demand%20of%20the%20IVF%20industry."
                }
              >
                <Icon as={FaBaby} w={20} h={20} color="blue.500" p={2} />
                <Text fontWeight={"extrabold"}>Fertility Solutions</Text>
              </Link>
            </GridItem>
            <GridItem
              w="100%"
              as={motion.div}
              whileHover={{ scale: 1.2, color: "#3182ce" }}
              cursor={"pointer"}
            >
              <Link to={"https://www.escohealthcare.com/"}>
                <Icon
                  as={TbRibbonHealth}
                  w={20}
                  h={20}
                  color="blue.500"
                  p={2}
                />
                <Text fontWeight={"extrabold"}>Healthcare Solutions</Text>
              </Link>
            </GridItem>
            <GridItem
              w="100%"
              as={motion.div}
              whileHover={{ scale: 1.2, color: "#3182ce" }}
              cursor={"pointer"}
            >
              <Link to={"https://www.escolifesciences.com/solutions/chemicals"}>
                <Icon
                  as={GiChemicalDrop}
                  w={20}
                  h={20}
                  color="blue.500"
                  p={2}
                />
                <Text fontWeight={"extrabold"}>Chemical Research</Text>
              </Link>
            </GridItem>
          </Grid>
        </Container>
      </Box>

      {/* Section one */}
      <Container maxW={"container.lg"} mb={40}>
        <SimpleGrid columns={["1", "2", "3"]} spacing={10}>
          <Box
            boxShadow={
              "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px"
            }
            borderRadius={10}
          >
            <Image
              src={one}
              boxSize="150px"
              objectFit="cover"
              margin={"auto"}
              my={10}
              as={motion.img}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            ></Image>

            <Text
              color={"blue.500"}
              fontWeight={"extrabold"}
              p={5}
              borderRadius={10}
            >
              We have a customer-centric approach and are always available to
              answer questions and provide support to our clients.
            </Text>
          </Box>

          <Box
            boxShadow={
              "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px"
            }
            borderRadius={10}
          >
            <Image
              src={two}
              boxSize="150px"
              objectFit="cover"
              margin={"auto"}
              my={10}
              as={motion.img}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            ></Image>
            <Text
              color={"white"}
              fontWeight={"extrabold"}
              bg="blue.500"
              p={5}
              borderRadius={10}
            >
              We work closely with healthcare professionals to ensure that they
              have access to the products they need to provide the best possible
              care to their patients.
            </Text>
          </Box>

          <Box
            boxShadow={
              "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px"
            }
            borderRadius={10}
          >
            <Image
              src={three}
              boxSize="150px"
              objectFit="cover"
              margin={"auto"}
              my={10}
              as={motion.img}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            ></Image>
            <Text
              color={"blue.500"}
              fontWeight={"extrabold"}
              p={5}
              borderRadius={10}
            >
              We believe in building long-term relationships with our clients,
              based on trust, transparency, and reliability.
            </Text>
          </Box>

          <Box
            boxShadow={
              "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px"
            }
            borderRadius={10}
          >
            <Image
              src={four}
              boxSize="150px"
              objectFit="cover"
              margin={"auto"}
              my={10}
              as={motion.img}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            ></Image>
            <Text
              color={"blue.500"}
              fontWeight={"extrabold"}
              p={5}
              borderRadius={10}
            >
              Our logistics team works diligently to ensure that all products
              are delivered on time and in excellent condition.
            </Text>
          </Box>

          <Box
            boxShadow={
              "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px"
            }
            borderRadius={10}
          >
            <Image
              src={five}
              boxSize="150px"
              objectFit="cover"
              margin={"auto"}
              my={10}
              as={motion.img}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            ></Image>
            <Text
              color={"blue.500"}
              fontWeight={"extrabold"}
              p={5}
              borderRadius={10}
            >
              We have a rigorous quality control process in place to ensure that
              all of our products meet the highest standards of quality and
              safety.
            </Text>
          </Box>

          <Box
            boxShadow={
              "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px"
            }
            as={motion.div}
            whileHover={{
              boxShadow:
                "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
            }}
            borderRadius={10}
          >
            <Image
              src={six}
              boxSize="150px"
              objectFit="cover"
              margin={"auto"}
              my={10}
              as={motion.img}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            ></Image>
            <Text
              color={"blue.900"}
              fontWeight={"extrabold"}
              bg="blue.100"
              p={5}
              borderRadius={10}
            >
              Our ultimate goal is to improve the health and well-being of
              people around the world by providing access to high-quality life
              sciences solutions.
            </Text>
          </Box>
        </SimpleGrid>
      </Container>

      {/* Section two */}
      <Container maxW={"container.lg"} mb={40}>
        <Card bg={"#e9f1f8"} color={"#396e93"}>
          <CardBody>
            <Stack
              divider={<StackDivider />}
              spacing="4"
              direction={["column", "row"]}
              textAlign={"center"}
            >
              <Box width={"full"}>
                <Heading size="xl" textTransform="uppercase">
                  <CountUp
                    end={100}
                    duration={3}
                    enableScrollSpy={true}
                    scrollSpyOnce={true}
                  />
                  +
                </Heading>
                <Text pt="2" fontSize="xl" color={"#fc8669"}>
                  Clients Served
                </Text>
              </Box>
              <Box width={"full"}>
                <Heading size="xl" textTransform="uppercase">
                  <CountUp
                    end={50000}
                    duration={3}
                    enableScrollSpy={true}
                    scrollSpyOnce={true}
                  />
                  +
                </Heading>
                <Text pt="2" fontSize="xl" color={"#fc8669"}>
                  Products Sold
                </Text>
              </Box>
              <Box width={"full"}>
                <Heading size="xl" textTransform="uppercase">
                  <CountUp
                    end={40}
                    duration={3}
                    enableScrollSpy={true}
                    scrollSpyOnce={true}
                  />
                  +
                </Heading>
                <Text pt="2" fontSize="xl" color={"#fc8669"}>
                  Work Locations
                </Text>
              </Box>
            </Stack>
          </CardBody>
        </Card>
      </Container>

      {/* Section four */}
      <Container maxW={"container.lg"} mb={40}>
        <SimpleGrid columns={["1", "3"]} spacingX="10px">
          <Box
            backgroundImage={wave}
            as={motion.div}
            initial={{ opacity: 0, scale: 0.9 }}
            whileInView={{ opacity: 1, scale: 1 }}
            p={10}
            borderRightRadius={["30%", "50%", "70%", "full"]}
            color={"white"}
          >
            <Heading mb={10} fontSize={"2xl"}>
              Cell Culture Monitoring System
            </Heading>
            <Text>
              Kits and devices that facilitates monitoring of different cell
              culture parameters.
            </Text>
          </Box>
          <Box
            as={motion.div}
            initial={{ opacity: 0, scale: 0.9 }}
            whileInView={{ opacity: 1, scale: 1 }}
            p={10}
          >
            <Heading mb={10} fontSize={"2xl"}>
              Biological Safety Cabinet
            </Heading>
            <Text>
              To guarantee the biological safety cabinet’s optimum performance
              these are tested and classified by 2 standards: EN 12469:2000 and
              NSF 49.
            </Text>
          </Box>
          <Box
            backgroundImage={wave3}
            as={motion.div}
            initial={{ opacity: 0, scale: 0.9 }}
            whileInView={{ opacity: 1, scale: 1 }}
            p={10}
            color={"white"}
            borderRadius={20}
          >
            <Heading mb={10} fontSize={"2xl"}>
              Filtered Storage Cabinet
            </Heading>
            <Text>
              Fumes from chemical containers often result in the storage
              cabinet’s corrosion and contaminated air in the laboratory.
            </Text>
          </Box>
        </SimpleGrid>
      </Container>

      {/* Section five */}
      <Container maxW={"container.lg"} backgroundImage={map} mb={40}>
        <Card align="center" opacity={0.9} p={["5", "10"]}>
          <CardHeader>
            <Heading size="lg">We are Working Together</Heading>
          </CardHeader>
          <CardBody>
            <Text textAlign={"center"} fontWeight={"bold"}>
              At our core, we believe that healthcare is a fundamental human
              right, and we are committed to doing our part to ensure that life
              sciences professionals have access to the latest and most advanced
            </Text>
          </CardBody>
          <CardFooter>
            <Button colorScheme="blue">
              <Link to="/Contact">Connect with us</Link>
            </Button>
          </CardFooter>
        </Card>
      </Container>

      {/* Section six */}
      <Container
        maxW={"container.lg"}
        mb={40}
        as={motion.div}
        initial={{ opacity: 0, x: "-100px" }}
        whileInView={{ opacity: 1, x: 0 }}
      >
        <Heading mb={10}>Our Narrative</Heading>
        <Card
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
        >
          <Image
            objectFit="contain"
            maxW={{ base: "100%", sm: "300px" }}
            src={medical}
            alt="Medical"
          />

          <Stack>
            <CardBody>
              <Heading size="md" mb={10}>
                Welcome to NVAK Biotech Instruments
              </Heading>

              <Text py="2">
                In 2021, NVAK Biotech Instruments began to focus more on
                biological sciences. A unique cross-border approach will be used
                by NVAK Biotech Instruments to create a global life sciences
                ecosystem. Founded by,{" "}
                <Text as="span" color={"tomato"} fontWeight={"extrabold"}>
                  {" "}
                  Mr. Vamsi Krishna{" "}
                </Text>
                who has 14 years of expertise in the same field, namely life
                sciences and biotechnology, and who has a thorough understanding
                of all.
              </Text>
              <Text py="2" color={"orange.500"}>
                At NVAK Biotech Instruments, we recognise the value of
                conducting ground-breaking study with the best tools and
                technology available. In order to give our clients the tools
                they need to perform their job to the highest standards, we are
                dedicated to locating and importing the best equipment from
                around the world.
              </Text>

              <Text py="2">
                Our product range includes a variety of instruments and
                equipment for use in research, testing, and analysis, including:
              </Text>
              <UnorderedList color={"orange.500"}>
                <ListItem>
                  Chemical analysis equipment for DNA, fumes, and other
                  applications
                </ListItem>
                <ListItem>
                  Biological safety cabinets and ducted/ductless fume hoods for
                  pharma culture, animal culture, and more
                </ListItem>
                <ListItem>
                  Instruments for animal gene research, virus research, and
                  COVID-19 research
                </ListItem>

                <ListItem>
                  Equipment for IVF and test tube baby procedures
                </ListItem>
              </UnorderedList>

              <Text py="2" color={"orange.500"}>
                At NVAK Biotech Instruments, we believe in delivering
                exceptional service to our clients. We work closely with our
                customers to understand their specific needs and requirements
                and offer tailored solutions that meet their needs. We pride
                ourselves on our ability to provide outstanding customer service
                and technical support, ensuring that our clients receive the
                best possible service from start to finish.
              </Text>

              <Text py="2">
                We take pride in our track record of success and our reputation
                for delivering high-quality equipment and exceptional service to
                our clients. We are committed to building long-lasting
                partnerships with our clients, based on trust, reliability, and
                excellent service.
              </Text>

              <Text py="2">
                Thank you for considering NVAK Biotech Instruments as your
                partner in research. We look forward to working with you to
                achieve your goals and further your important work.
              </Text>
            </CardBody>

            <CardFooter>
              <Link to="/Contact">
                <Button variant="solid" colorScheme="blue">
                  Get in touch
                </Button>
              </Link>
            </CardFooter>
          </Stack>
        </Card>
      </Container>

      {/* Section Client seven */}
      <Box p={10}>
        <Container maxW={"container.sm"}>
          <Heading mb={20} textAlign={"center"} fontSize={"3xl"}>
            Happy to work with clients
          </Heading>
          <ClientsCarousel />
        </Container>
      </Box>
      <GoToTop />
    </Box>
  );
}

const ClientsCarousel = () => (
  <Carousel
    autoPlay
    infiniteLoop
    interval={2000}
    showStatus={false}
    showThumbs={false}
    showArrows={false}
  >
    <Box width={"full"}>
      <Image src={laurus} maxWidth={"200px"}></Image>
    </Box>

    <Box width={"full"}>
      <Image src={gland} maxWidth={"200px"}></Image>
    </Box>

    <Box width={"full"}>
      <Image src={vimta} maxWidth={"200px"}></Image>
    </Box>

    <Box width={"full"}>
      <Image src={mylan} maxWidth={"200px"}></Image>
    </Box>

    <Box width={"full"}>
      <Image src={drreddy} maxWidth={"200px"}></Image>
    </Box>

    <Box width={"full"}>
      <Image src={bharat} maxWidth={"200px"}></Image>
    </Box>

    <Box width={"full"}>
      <Image src={divis} maxWidth={"200px"}></Image>
    </Box>

    <Box width={"full"}>
      <Image src={niab} maxWidth={"200px"}></Image>
    </Box>

    <Box width={"full"}>
      <Image src={aurobindo} maxWidth={"200px"}></Image>
    </Box>

    <Box width={"full"}>
      <Image src={msn} maxWidth={"200px"}></Image>
    </Box>

    <Box width={"full"}>
      <Image src={hetero} maxWidth={"200px"}></Image>
    </Box>

    <Box width={"full"}>
      <Image src={aurigene} maxWidth={"200px"}></Image>
    </Box>

    <Box width={"full"}>
      <Image src={aragen} maxWidth={"200px"}></Image>
    </Box>

    <Box width={"full"}>
      <Image src={be} maxWidth={"200px"}></Image>
    </Box>
  </Carousel>
);

export default Home;
