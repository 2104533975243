import {
  Container,
  Card,
  CardBody,
  Text,
  Heading,
  CardHeader,
  Image,
  Stack,
  SimpleGrid,
  UnorderedList,
  ListItem,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Divider,
  Button,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

function MiriMultiRoom() {
  return (
    <Container maxW={"container.lg"}>
      <Card mb={20}>
        <CardHeader>
          <Heading size="lg">MIRI® Multiroom Incubator</Heading>
        </CardHeader>
        <CardBody>
          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            mb={20}
          >
            <Image
              objectFit="cover"
              maxW={{ base: "100%", sm: "200px" }}
              src="https://nvakins.in/wp-content/uploads/2022/02/miri-multiroom-incubator-product-image-min-hd-600x582.png"
              alt="MIRI® Multiroom Incubator"
            />

            <Stack>
              <CardBody>
                <Text py="2">
                  Vertical laminar flow workstations with multiple heating zones
                  that enable precise temperature control across the work
                  surface, with the provision to have a built-in microscope and
                  integrated MIRI® chambers. Available in different sizes and
                  configurations.
                </Text>
              </CardBody>
            </Stack>
          </Card>

          <Heading size={"md"} mb={20} color={"blue.500"}>
            Parts of the MIRI® Multiroom Incubator
          </Heading>
          <Image
            src="https://www.esco-medical.com/images/upload/MIRI-Incubator-min.png"
            mb={20}
            alt={"Parts of the MIRI® Multiroom Incubator"}
          ></Image>

          <Card mb={10}>
            <CardHeader>
              <Heading size="md" color={"blue.500"}>
                {" "}
                Superior Incubation For Your Samples
              </Heading>
            </CardHeader>
            <CardBody>
              <Text>
                The Esco MIRI® Multiroom incubator is the best option for
                securing embryos in your IVF laboratory. With its six (6)
                independent chambers, not only does it provide embryologists a
                bigger capacity to accommodate more embryos. It also ensures
                that environmental parameters are maintained even when the
                neighboring chambers are opened. It also gives the best
                temperature recovery, accuracy, and uniformity in the IVF
                incubator market with its heated lid design. This feature
                ensures that temperature given to your embryos are not only
                sourced from the bottom of the dish but is also given on the top
                with a set point of 37.2C to ensure that exactly 37.0C is given
                to your samples.
              </Text>
            </CardBody>
          </Card>

          <SimpleGrid
            spacing={10}
            templateColumns={[
              "repeat(auto-fill, minmax(200px, 1fr))",
              "repeat(auto-fill, minmax(350px, 1fr))",
            ]}
            mb={20}
          >
            <Card>
              <CardHeader>
                <Heading size="md" color={"blue.500"}>
                  Cost-Effective And Flexibility
                </Heading>
              </CardHeader>
              <CardBody>
                <Text>
                  The Esco MIRI® Multiroom incubator gives you the option to use
                  pure N2 and CO2 gases which are cheaper than premixed gases
                  and recirculates these gas inserts giving you the best value
                  for money. This also gives you the flexibility to change your
                  gas concentration when you want to optimize embryo culture
                  protocols in your laboratory. The MIRI® Multiroom is also
                  compatible with the most common brands of culture dishes in
                  the market with the corresponding capacity per incubator
                  chamber below.
                </Text>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <Heading size="md" color={"blue.500"}>
                  {" "}
                  Total Capacity
                </Heading>
              </CardHeader>
              <CardBody>
                <Text>
                  Heating plates customized for several types of dishes:
                </Text>
                <UnorderedList>
                  <ListItem>4 x Falcon® ⌀ 50/60 mm</ListItem>
                  <ListItem>8 x Falcon® ⌀ 35 mm</ListItem>
                  <ListItem>4 x Nunc™ ⌀ 54/60 mm</ListItem>
                  <ListItem>8 x Nunc™ ⌀ 35 mm</ListItem>
                  <ListItem>4 x VitrolifeDishes</ListItem>
                  <ListItem>4 x LifeGlobal® GPS Dishes</ListItem>
                  <ListItem>4 x Nipro™</ListItem>
                  <ListItem>4 x SparMED Oosafe®</ListItem>
                </UnorderedList>
              </CardBody>
            </Card>
          </SimpleGrid>

          <Divider mb={20}></Divider>

          {/* Tables one */}
          <TableContainer mb={20}>
            <Table variant="simple">
              <TableCaption bg={"blue.500"} color={"white"}>
                Models
              </TableCaption>
              <Thead>
                <Tr>
                  <Th color={"blue.500"}>Item Code </Th>
                  <Th color={"blue.500"}>Model Code </Th>
                  <Th color={"blue.500"}>Description</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>2070047 </Td>
                  <Td>MRI-6A10-8 </Td>
                  <Td>MIRI® Incubator, 230V, 50/60Hz</Td>
                </Tr>

                <Tr>
                  <Td>2070048</Td>
                  <Td>MRI-6A10-9 </Td>
                  <Td>MIRI® Incubator, 115V, 50/60Hz</Td>
                </Tr>

                <Tr>
                  <Td>2070086 </Td>
                  <Td>MRI-6A10-SS-8 </Td>
                  <Td>
                    MIRI® Incubator, with SAFE Sens for pH measurement, 230V,
                    50/60Hz
                  </Td>
                </Tr>

                <Tr>
                  <Td>2070087 </Td>
                  <Td>MRI-6A10-SS-9 </Td>
                  <Td>
                    MIRI® Incubator, with SAFE Sens for pH measurement, 115V,
                    50/60Hz
                  </Td>
                </Tr>

                <Tr>
                  <Td>1320045 </Td>
                  <Td> MRI-GA </Td>
                  <Td>
                    MIRI® GA CO2 / O2 & Temperature Validation Unit, 115V / 230V
                  </Td>
                </Tr>

                <Tr>
                  <Th> </Th>
                  <Th> </Th>
                  <Th color={"blue.500"}>Accessories</Th>
                </Tr>

                <Tr>
                  <Td> 1320140</Td>
                  <Td>TBA </Td>
                  <Td>PC Holder for MIRI®</Td>
                </Tr>

                <Tr>
                  <Td> 1320141</Td>
                  <Td> TBA</Td>
                  <Td>Tablet PC for Data Logging of MRI-6A10</Td>
                </Tr>

                <Tr>
                  <Td>1320142 </Td>
                  <Td>TBA </Td>
                  <Td>
                    Set of Tablet PC and Holder For Data Logging of MRI-6A10
                  </Td>
                </Tr>

                <Tr>
                  <Td>1320191 </Td>
                  <Td> TBA</Td>
                  <Td>
                    SAFE Sens TrakStation, a tablet with SAFE Sens Software, for
                    pH monitoring
                  </Td>
                </Tr>

                <Tr>
                  <Td> 1320011</Td>
                  <Td> MRA-1007 </Td>
                  <Td>
                    HEPA+VOC filter (recommended to be replaced every 3 months)
                  </Td>
                </Tr>

                <Tr>
                  <Td>1320018 </Td>
                  <Td> MRA-1014 </Td>
                  <Td>
                    Stacking frame for 2 units 1320226 TBA Stacking Frame for 2
                    Units, With Drawer at the Bottom
                  </Td>
                </Tr>

                <Tr>
                  <Td> 1081277</Td>
                  <Td>TBA </Td>
                  <Td>
                    SAFE Sens SV2 Sensor, Pack of 10 pieces (shelf-life 12
                    months)
                  </Td>
                </Tr>

                <Tr>
                  <Td> 1081278</Td>
                  <Td> TBA</Td>
                  <Td>SAFE Sens QC2 Alignment Tool</Td>
                </Tr>
              </Tbody>
              <Tfoot></Tfoot>
            </Table>
          </TableContainer>

          {/* Tables two */}
          <TableContainer>
            <Table variant="simple">
              <TableCaption>
                <Link to={"/Contact"}>
                  <Button colorScheme={"blue"}>Contact Us</Button>
                </Link>
              </TableCaption>
              <Thead>
                <Tr>
                  <Th color={"blue.500"}>Item Code </Th>
                  <Th color={"blue.500"}>Model Code </Th>
                  <Th color={"blue.500"}>Description</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Th> </Th>
                  <Th> </Th>
                  <Th color={"blue.500"}>Accessories</Th>
                </Tr>
                <Tr>
                  <Td>132003 </Td>
                  <Td>TBA</Td>
                  <Td>Insert for Falcon® Dishes</Td>
                </Tr>

                <Tr>
                  <Td>132004 </Td>
                  <Td>TBA</Td>
                  <Td>Insert for NUNC® Dishes</Td>
                </Tr>

                <Tr>
                  <Td>1320070 </Td>
                  <Td>TBA </Td>
                  <Td>Insert for Vitrolife® Dishes</Td>
                </Tr>

                <Tr>
                  <Td>1320099 </Td>
                  <Td>TBA</Td>
                  <Td>Insert for Nipro Dishes</Td>
                </Tr>

                <Tr>
                  <Td>1320100 </Td>
                  <Td> TBA</Td>
                  <Td>Insert for LifeGlobal/ GPS Dishes™</Td>
                </Tr>

                <Tr>
                  <Td> 1320101 </Td>
                  <Td>TBA </Td>
                  <Td>Insert Without Footprint for Plain Dishes</Td>
                </Tr>

                <Tr>
                  <Td> 1320118 </Td>
                  <Td> TBA</Td>
                  <Td>Insert for Sparmed – Oosafe™</Td>
                </Tr>

                <Tr>
                  <Td>1320219 </Td>
                  <Td>TBA </Td>
                  <Td>Insert for Falcon® Dishes, with hole for SAFE Sens</Td>
                </Tr>

                <Tr>
                  <Td>1320220 </Td>
                  <Td> TBA</Td>
                  <Td>Insert for NUNC® Dishes, with hole for SAFE Sens</Td>
                </Tr>

                <Tr>
                  <Td> 1320221</Td>
                  <Td>TBA </Td>
                  <Td>Insert for Vitrolife® Dishes, with hole for SAFE Sens</Td>
                </Tr>

                <Tr>
                  <Td>1320222 </Td>
                  <Td>TBA</Td>
                  <Td>Insert for Nipro Dishes, with hole for SAFE Sens</Td>
                </Tr>

                <Tr>
                  <Td> 1320223</Td>
                  <Td>TBA </Td>
                  <Td>
                    Insert for LifeGlobal/ GPS Dishes™, with hole for SAFE Sens
                  </Td>
                </Tr>

                <Tr>
                  <Td> 1320224 </Td>
                  <Td> TBA</Td>
                  <Td>
                    Insert Without Footprint for Plain Dishes, with hole for
                    SAFE Sens 1
                  </Td>
                </Tr>

                <Tr>
                  <Td> 1320225 </Td>
                  <Td> TBA</Td>
                  <Td>Insert for Sparmed – Oosafe™, with hole for SAFE Sens</Td>
                </Tr>
              </Tbody>
              <Tfoot></Tfoot>
            </Table>
          </TableContainer>
        </CardBody>
      </Card>
    </Container>
  );
}

export default MiriMultiRoom;
